import { instance } from '@/utils/http.js'

// 获取首页推荐为的答疑
export const getHomeDiscuss = function () {
  return instance({
    url: '/api/v1/home/line_discuss_pc',
    method: 'get',
  })
}


// 获取板块
export const getBoard = function () {
  return instance({
    url: '/api/v1/discuss/get_user_boards',
    method: 'get',
  })
}

// 根据板块id获取答疑列表
export const getBoardList = function (params) {
  return instance({
    url: '/api/v1/discuss/index',
    method: 'get',
    params
  })
}
