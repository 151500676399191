<template>
  <div>
    <el-dialog title="发帖"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="800rem"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="100rem">

        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="科目:"
                          prop="board_id">
              <el-select style="width:100%"
                         v-model="form.board_id"
                         placeholder="请选择科目">
                <el-option v-for="item in $parent.boardList"
                           :key="item.board_id"
                           :label="item.board_name"
                           :value="item.board_id">
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="标题:"
                          prop="discuss_title">
              <el-input placeholder="请输入标题"
                        v-model="form.discuss_title"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="内容:"
                          prop="discuss_content">
              <el-input placeholder="请输入内容"
                        style="width:100%"
                        v-model="form.discuss_content"
                        type="textarea"
                        :rows="6" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="题目:">
              <el-input style="width:100%"
                        v-model="form.question_id"
                        type="number"
                        placeholder="输入题目id，请仔细检查题目ID是否正确。" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="图片:">
              <div class="images">
                <div v-for="(item,index) in images"
                     :key="index">
                  <img :src="item"
                       style=" width: 100rem;height: 100rem;margin-right:20rem;margin-bottom:20rem"
                       alt="">
                  <img class="del"
                       @click="delImage(index)"
                       src="@/assets/icons/删除.png"
                       alt="">
                </div>
                <div class="add_img"
                     v-if="images.length  < 2"
                     @click="uploadImage">
                  <div>
                    <img src="@/assets/add_img.png"
                         alt="">
                  </div>
                  点击上传
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <div class="btn_warp">
        <div @click="handleClose">退出</div>
        <div @click="save">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadImageAuto } from '@/api/upload.js'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      images: [],
      rules: {
        discuss_title: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        discuss_content: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        board_id: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      }
    }
  },
  mounted () {
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.images = []
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.question_id += ''
        // if (form.question_id) {
        //   form.question_id = form.question_id.slice(4, form.question_id.length)
        // }

        form.discuss_image = this.images.join(',')
        this.$http({
          url: '/api/v1/discuss/new_post_done',
          method: 'post',
          data: form
        }).then(res => {
          this.$message.success('发帖成功')
          this.handleClose()
          this.$parent.initList()
        })
      })
    },
    async uploadImage () {
      const { data } = await uploadImageAuto()
      this.images.push(data.info.dir)
    },
    addImage (str) {
      if (!str) {
        this.$message.warning('失败')
        return
      }
      this.images.push(this.$addHeader(str))
    },
    delImage (index) {
      this.$message.success('删除成功')
      this.images.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23rem;
  font-size: 16rem;
  padding: 27rem;
  padding-top: 0;
}
.btn_warp {
  width: 100%;
  height: 70rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24rem;
    &:nth-child(1) {
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
  height: calc(100% - 160rem);

  overflow-y: auto;
  overflow-x: hidden;
}

::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
  padding: 20rem;
}
::v-deep .el-dialog {
  border-radius: 10rem;
}

::v-deep .el-textarea__inner {
  font-size: 16rem;
}

.add_img {
  width: 100rem;
  height: 100rem;
  font-size: 20rem;
  font-weight: 500;
  background: #f6f6f6;
  border-radius: 5rem;
  text-align: center;
  color: #2196f3;
  border-radius: 5rem;
  img {
    width: 42rem;
    height: 34rem;
    margin-top: 19rem;
    margin-bottom: 6rem;
  }
}
.images {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-dialog {
  height: 700rem;
}
::v-deep .el-input__inner {
  height: 45rem;
  line-height: 45rem;
  font-size: 16rem;
}
::v-deep .el-form-item__label {
  line-height: 45rem;
  font-size: 18rem;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__title {
  font-size: 24rem;
}
.images {
  div {
    position: relative;
    .del {
      position: absolute;
      right: 10rem;
      top: -15rem;
      width: 30rem;
      height: 30rem;
      cursor: pointer;
    }
  }
}
</style>